<template>
  <!-- 行驶数据 -->
  <div>
    <ul id="tab_B" class="tab" slot="extra">
      <li
        :class="{ current: tabCurrent === 0 }"
        @click="changeTab(0)"
      >
        总里程
      </li>
      <li
        :class="{ current: tabCurrent === 1 }"
        @click="changeTab(1)"
      >
        今日里程
      </li>
    </ul>
    <div :class="$style.chart">
      <div :class="$style.chartWrap" :style="`transform: translateX(${tabCurrent * -16}rem);`">
        <div id="mileageTrends" style="width: 16rem; height: 6rem"></div>
        <div id="daliyMileageTrend" style="width: 16rem; height: 6rem"></div>
      </div>
    </div>
  </div>
</template>

<style module lang="scss">
.chart {
  width: 100%;
  overflow: hidden;

  .chartWrap {
    display: flex;
    width: 200%;
    transition: transform .3s cubic-bezier(0.4, 0, 0.2, 1);
  }
}
</style>

<script>
import { Chart } from "@antv/g2";

const { remUnit = 20 } = window;

export default {
  props: {
    title: {
      type: String,
    },
    large: {
      type: Boolean,
      default: false,
    },
    vData: {
      type: Object,
      default: () => ({
        mileageTrends: [],
        daliyMileageTrend: [],
      }),
    },
  },
  data() {
    return {
      tabCurrent: 0,
      charts: {},
    };
  },
  mounted() {
    const { mileageTrends, daliyMileageTrend } = this.vData;

    this.initChart('mileageTrends', 'statDate', 'driveMiles', mileageTrends);
    this.initChart('daliyMileageTrend', 'hour', 'driveMiles', daliyMileageTrend);

    this.changeTab();
  },
  watch: {
    vData(val) {
      const { mileageTrends, daliyMileageTrend } = val;

      this.initChart('mileageTrends', 'statDate', 'driveMiles', mileageTrends);
      this.initChart('daliyMileageTrend', 'hour', 'driveMiles', daliyMileageTrend);
    }
  },
  methods: {
    changeTab(idx) {
      if (this.timer) clearInterval(this.timer);

      if (typeof(idx) === 'number') this.tabCurrent = idx;

      this.timer = setInterval(() => {
        this.tabCurrent = 1 - this.tabCurrent;
      }, 15000)
    },
    initChart(id, xAxis, yAxis, data) {
      let chart = this.charts[id];

      if (!data || data.length === 0) return;

      if (chart) {
        chart.changeData(data);

        return;
      }

      chart = new Chart({
        container: id,
        autoFit: true,
        padding: [0.5 * remUnit, remUnit, 1.5 * remUnit, 1.5 * remUnit],
      });

      chart.scale({
        [xAxis]: {
          range: [0, 1],
        },
        [yAxis]: {
          nice: true,
        },
      });

      chart.tooltip({
        showCrosshairs: true,
        shared: true,
        customContent: (name, items) => {
          const container = document.createElement("div");
          container.className = "g2-tooltip";
          const title = `<div class="custom-tooltip"><h4>${name}</h4>`;
          let listItem = "";
          items.forEach((item) => {
            listItem += `<p>${item.value}km</p>`;
          });
          container.innerHTML = title + listItem + "</div>";

          return container;
        }
      });

      chart.axis(xAxis, {
        grid: {
          line: {
            style: {
              stroke: "#343B74",
            },
          },
        },
        line: null,
        tickLine: null,
        label: {
          style: {
            fill: "#5961A6",
          },
          formatter: (val) => {
            return val.replace(/\d{4}-/, '');
          },
        },
      });
      chart.axis(yAxis, {
        grid: null,
        label: {
          style: {
            fill: "#5961A6",
          },
        },
      });

      chart.area().position(`${xAxis}*${yAxis}`).style({
        fillOpacity: 1,
        fill: "l(270) 1:#FF3C5F 0:#2C315D",
      }).shape('smooth');
      chart.line().position(`${xAxis}*${yAxis}`).style({
        stroke: "#FF3C5F",
      }).shape('smooth');

      chart.data(data);
      chart.render();
      this.charts[id] = chart;
    }
  },
};
</script>